import api from "@/api"
import Toast from "../../components/Toast/Toast";
import Vue from "vue";

const RetroTest = {
    state: () => ({
        RetroSettings: {
            "amount": 0,
            "leverage": 0,
            "limit": 0,
            "hedge": 0,
            "stop1": 0,
            "stop2": 0,
            "take1": 0,
            "take2": 0
        },
        RetroSmiio: {},
        RetroPNL: {},
        RetroDeals: {}
    }),
    mutations: {
        writeAnswer(state, payload) {
            state[payload.to] = payload.data
        },
    },
    actions: {
        async getRETRO_SETTINGS({commit}, payload) {
            const data = await api.getRetroTest({args: `${payload}`})
            commit('writeAnswer', {to: "RetroSettings", data: data.results.data})
            commit('writeTitle', data.results.title)
        },
        async setRETRO_SETTINGS({commit}, { settings, offset }) {
            const result = await api.putRetroTest({data: settings, args: `${offset}/`})
            const content = {}
            if (result.results.Success) {
                commit('writeAnswer', {to: "RetroSettings", data: settings})
                content.component = Toast
                content.props = {
                    toastProps: {
                        title: `Новые настройки успешно сохранены`,
                        content: "Подождите идет расчет"
                    }
                }
            } else {
                content.component = Toast
                content.props = {
                    toastProps: {
                        title: `Ошибка в сохранении начтроек`,
                        content: "Error"
                    }
                }
            }
            Vue.$toast(content)
        },
        async getRETRO_DATA({commit}) {
            commit('writeLoader', true)
            const data = await api.getRetroData()
            commit('writeAnswer', {to: "RetroSmiio", data: data.results})
            commit('writeLoader', false)
        },
        clearRetroData({commit}) {
            commit('writeAnswer', {to: "RetroSmiio", data: {}})
        },
        async getRetroPNL({commit}) {
            commit('writeLoader', true)
            const {results} = await api.getRetroPNL()
            commit('writeAnswer', {to: "RetroPNL", data: {data: results.data, settings: results.settings}})
            commit('writeLoader', false)
        },
        async getRetroDeals({commit}) {
            commit('writeLoader', true)
            const {results} = await api.getRetroDeals()
            commit('writeAnswer', {to: "RetroDeals", data: {data: results.data, settings: results.settings}})
            commit('writeLoader', false)
        },
    },
    getters: {
        RETRO_SETTINGS(state) {
            return state.RetroSettings
        },
        RETRO_DATA(state) {
            return state.RetroSmiio
        },
        RETRO_DEALS(state) {
            return state.RetroDeals
        },
        RETRO_PNL(state) {
            return state.RetroPNL
        },
    }
}
export default RetroTest
