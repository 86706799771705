import api from "@/api"

const TelegramModule = {
    state: () => ({
        channels: [],
        channelsPageCount: 0,
        forControlsChannels: [],
        selectedChannels: []
    }),
    mutations: {
        writeChannels(state, payload) {
            state.channels = payload.channels
            state.channelsPageCount = payload.pages
        },
        wrightControlsChannels(state, payload) {
            state.forControlsChannels = payload
        },
        wrightSelectedChannels(state, payload) {
            state.selectedChannels = payload
        }
    },
    actions: {
        async getCHANNELS({commit}, {page, bot}) {
            let args = page ? `?page=${page}` : ''
            args = `${args}&bot=${bot}`
            try {
                const data = await api.getTelegramChannels({args: args})
                const pages = Math.ceil(data.count / 50)
                commit('writeChannels', {channels: data.results.data, pages: pages})
                // commit('writeTitle', data.results.title)
                commit('writeFilters', {filters: []})

            } catch (err) {
                console.log('error', err)
            }
        },
        async getCHANNELS_FOR_CONTROL({commit}, {bot}) {
            const args = `?bot=${bot}`
            try {
                const data = await api.getTelegramChannels({args: args})
                commit('wrightControlsChannels', data.results.data)
            } catch (err) {
                console.log('error', err)
            }
        },
        async set_CORRESPONDENT_CHANNELS(context, {id, patch, to_channel}) {
            try {
                const data = await api.setCorrespondingChannel({args: `${id}/`, data: {patch, to_channel}})
                if (!data.Success) console.log(data.Errors)
            } catch (err) {
                console.log('error', err)
            }
        },
        async get_INDICATOR_CORRESPONDENT_CHANNELS({commit}) {
            try {
                const data = await api.indicatorCorrespondents()
                commit('wrightSelectedChannels', data.data)
            } catch (err) {
                console.log('error', err)
            }
        },
        async set_INDICATOR_CORRESPONDENT_CHANNELS(context, {patch, to_channel}) {
            try {
                const data = await api.setIndicatorCorrespondents({data: {patch, to_channel}})
                if (!data.Success) console.log(data.Errors)
            } catch (err) {
                console.log('error', err)
            }
        },
        async getCHANNEL_FROM({commit}, {id}) {
            try {
                const data = await api.getCorrespondingChannel({args: `${id}/`})
                if(data.Success) commit('wrightSelectedChannels', data.data)
            } catch (err) {
                console.log('error', err)
            }
        },
    },
    getters: {
        CHANNELS(state) {
            return state.channels
        },
        CHANNELS_PAGE_COUNT(state) {
            return state.channelsPageCount
        },
        GET_CONTROL_CHANNELS(state) {
            return state.forControlsChannels
        },
        GET_SELECTED_CHANNELS(state) {
            return state.selectedChannels
        },
    }
}

export default TelegramModule
