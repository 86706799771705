const refreshModule = {
    state: () => ({
        delay: 180000,
        refreshTimer: null
    }),
    mutations: {
        mutateTimer(state, callback) {
            state.refreshTimer = callback
        }
    },
    actions: {
        setREFRESH({commit, state}, callback) {
            if (state.refreshTimer) window.clearTimeout(state.refreshTimer)
            commit('mutateTimer', window.setTimeout(callback, state.delay))
        },
        clearREFRESH({state}) {
            window.clearTimeout(state.refreshTimer)
        }
    },
}

export default refreshModule