const PageTitleModule = {
    state: () => ({
        pageTitle: "",
        titlesList: {
            main: "Главная страница",
            dashboard: "Дашборд",
            signals: "Сигналы",
            "pivot-tables": "Сводные таблицы",
            twitter: "Парсинг",
            telegram: "Телеграм каналы",
            ath: "ATH",
            events: "Календарь событий",
            direvatives: "Деривативы",
            spot: "Спот",
            "smiio-signals": "Сигналы SMIIO стандартные",
            "retro-test": "Ретротест",
            "super-signals": "Сигналы MTF комбинированные"
        }
    }),
    mutations: {
        writeTitle(state, payload) {
            state.pageTitle = payload
        }
    },
    actions: {
        SAVE_TITLE({commit}, payload) {
            commit('writeTitle', payload)
        }
    },
    getters: {
        TITLE(state) {
            return state.titlesList[state.pageTitle] || ""
        },
    }
}

export default PageTitleModule
