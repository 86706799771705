import api from "@/api"
import {serializeTimeframe} from "../../serializer/timeframeSerializer";

const MainModule = {
    state: () => ({
        Dashboard: [],
        MoonPhase: [],
        SmaSmiio: {
            data: [],
            headers: [],
        },
    }),
    mutations: {
        writeAnswer(state, payload) {
            state[payload.to] = payload.data
        },
    },
    actions: {
        async getSIGNALS({commit}, payload) {
            const {filter, page} = payload
            const _filter = filter ? `symbol=${filter.length > 1 ? filter.join(",") : filter}&` : ""
            try {
                const data = await api.getSignal({args: `?${_filter}ordering=-time&page=${page}`})
                const pages = Math.ceil(data.count / 10)
                data.results.map(el => {
                    el.timeframe = serializeTimeframe(el.timeframe)
                    return el.timeframe
                })
                commit('writeAnswer', {to: "signals", data: data.results})
                commit('writeAnswer', {to: "signalsPageCount", data: pages})
                commit('writeTitle', "Сигналы")
            } catch (err) {
                console.log('error', err)
            }
        },
        async getDashboard({commit}) {
            const {results} = await api.getDashboard()
            commit('writeAnswer', {to: "Dashboard", data: results})
        },
        async getMoonPhase({commit}) {
            const {results} = await api.getMoonPhase()
            commit('writeAnswer', {to: "MoonPhase", data: results})
        },
        async getSmaSmiio({commit}) {
            const {results} = await api.getSmaSmiio()
            if (results && results.length > 0) {
                const headers = Object.keys(results[0]).map(el => {
                    return {
                        id: el,
                        title: el.toUpperCase().replace(/(\d+)/g, ' $1').replace('TIMEFRAME', 'BTC'),
                        style: el.includes('50') ? 'yellow-bg'
                            : el.includes('100') ? 'blue-bg'
                                : el.includes('200') ? 'purple-bg'
                                    : 'darkblue-bg',
                    }
                })
                const mappedResults = results.map(el => {
                        const newRow = {}
                        headers.forEach(header => {
                            header.id.includes('sma')
                                ? newRow[header.id] = '$ ' + el[header.id].toLocaleString("ru-RU", {
                                useGrouping: true,
                                minimumFractionDigits: 1,
                                maximumFractionDigits: 2
                            })
                                : header.id.includes('smiio')
                            ? newRow[header.id] = el[header.id].toLocaleString("ru-RU", {
                                useGrouping: true,
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                            }) : newRow[header.id] = el[header.id]
                        })
                    return newRow
                })
                commit('writeAnswer', { to: "SmaSmiio", data: { headers, data: mappedResults } })
            }
        },
    },
    getters: {
        DASBOARD(state) {
            return state.Dashboard
        },
        MOONPHASE(state) {
            return state.MoonPhase
        },
        SMASMIIO(state) {
            return state.SmaSmiio
        }
    }
}

export default MainModule
