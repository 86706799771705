import api from "@/api"
import dateFormatter from "../../formatters/dateFormatter";

const SupperModule = {
    state: () => ({
        superHeaders: {},
        superSignals: [],
        perPage: 24,
        totalPages: 1
    }),
    mutations: {
        writeAnswer(state, payload) {
            state[payload.to] = payload.data
        },
    },
    actions: {
        async getSUPPER_SIGNALS({commit}, {symbol, page}) {
            const data = await api.getCompositeSignals({params: {symbol, page, ordering: '-stamp'}})
            const [headers, dataset] = data.results
            commit('writeAnswer', {to: "superHeaders", data: headers})
            commit('writeAnswer', {to: "superSignals", data: dataset})
            if (data.count > 24) commit('writeAnswer', {
                to: 'totalPages',
                data: Math.ceil(data.count / 24)
            })
        },
    },
    getters: {
        SUPPER_HEADERS(state) {
            return state.superHeaders
        },
        SUPPER_SIGNALS(state) {
            return state.superSignals.map(el => {
                el.date = dateFormatter(el.stamp, false, false)
                el.time = dateFormatter(el.stamp, false, false, true)
                delete el.stamp
                return el
            })
        },
        SUPPER_SIGNALS_TOTAL_PAGES(state) {
            return state.totalPages
        }
    }
}
export default SupperModule
