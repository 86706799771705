// import api from "@/api"

const DashesModule = {
    state: () => ({
        cards: [],
        cardsPageCount: 0
    }),
    mutations: {
        writeCards(state, payload) {
            state.cards = payload.cards
            state.cardsPageCount = payload.pages
        }
    },
    actions: {
        async getCARDS({commit}, payload) {
            const getPage = payload ? `&page=${payload}` : ''
            console.log(getPage)
            try {
                // const data = await api.getCards({args: `?ordering=-signal_time${getPage}`})
                // const pages = Math.ceil(data.count / 50)
                // commit('writeAnswer', {signals: data.results.data, pages: pages})
                // commit('writeTitle', data.results.title)
                commit('writeCards', {cards: [
                        {
                            coinImg: "https://upload.wikimedia.org/wikipedia/commons/thumb/4/46/Bitcoin.svg/2048px-Bitcoin.svg.png",
                            coinName: "DOGE",
                            coinPrice: "33 994,10 USD",
                            dots: 4,
                            status: "buy",
                            profit: "-12,4%",
                            stockExchange: "Binance",
                            buyingTime: "12 Июля в 17:02:29",
                            sum: "0,00035121/12 USD",
                            timeFrame: "30m",
                            direction: "Медвежий",
                            bar: 1,
                            currentSignal: "Нулевой крест (Медвежий)",
                            force: "Слабый"
                        },
                        {
                            coinImg: "https://upload.wikimedia.org/wikipedia/commons/thumb/4/46/Bitcoin.svg/2048px-Bitcoin.svg.png",
                            coinName: "DOGE",
                            coinPrice: "33 994,10 USD",
                            dots: 4,
                            status: "buy",
                            profit: "-12,4%",
                            stockExchange: "Binance",
                            buyingTime: "12 Июля в 17:02:29",
                            sum: "0,00035121/12 USD",
                            timeFrame: "30m",
                            direction: "Медвежий",
                            bar: 2,
                            currentSignal: "Нет (Медвежий)",
                            force: "Слабый"
                        },
                        {
                            coinImg: "https://upload.wikimedia.org/wikipedia/commons/thumb/4/46/Bitcoin.svg/2048px-Bitcoin.svg.png",
                            coinName: "DOGE",
                            coinPrice: "33 994,10 USD",
                            dots: 4,
                            status: "buy",
                            profit: "-12,4%",
                            stockExchange: "Binance",
                            buyingTime: "12 Июля в 17:02:29",
                            sum: "0,00035121/12 USD",
                            timeFrame: "30m",
                            direction: "Медвежий",
                            bar: 3,
                            currentSignal: "Блюдце (Бычий)",
                            force: "Средний"
                        },
                        {
                            coinImg: "https://upload.wikimedia.org/wikipedia/commons/thumb/4/46/Bitcoin.svg/2048px-Bitcoin.svg.png",
                            coinName: "DOGE",
                            coinPrice: "33 994,10 USD",
                            dots: 4,
                            status: "buy",
                            profit: "-12,4%",
                            stockExchange: "Binance",
                            buyingTime: "12 Июля в 17:02:29",
                            sum: "0,00035121/12 USD",
                            timeFrame: "30m",
                            direction: "Медвежий",
                            bar: 4,
                            currentSignal: "Нулевой крест (Бычий)",
                            force: "Средний"
                        },
                        {
                            coinImg: "https://upload.wikimedia.org/wikipedia/commons/thumb/4/46/Bitcoin.svg/2048px-Bitcoin.svg.png",
                            coinName: "DOGE",
                            coinPrice: "33 994,10 USD",
                            dots: 4,
                            status: "buy",
                            profit: "-12,4%",
                            stockExchange: "Binance",
                            buyingTime: "12 Июля в 17:02:29",
                            sum: "0,00035121/12 USD",
                            timeFrame: "1h",
                            direction: "Медвежий",
                            bar: 5,
                            currentSignal: "Нулевой крест (Медвежий)",
                            force: "Средний"
                        },
                        {
                            coinImg: "https://upload.wikimedia.org/wikipedia/commons/thumb/4/46/Bitcoin.svg/2048px-Bitcoin.svg.png",
                            coinName: "DOGE",
                            coinPrice: "33 994,10 USD",
                            dots: 4,
                            status: "buy",
                            profit: "+12,4%",
                            stockExchange: "Binance",
                            buyingTime: "12 Июля в 17:02:29",
                            sum: "0,00035121/12 USD",
                            timeFrame: "30m",
                            direction: "Медвежий",
                            bar: 3,
                            currentSignal: "Блюдце (Медвежий)",
                            force: "Сильный"
                        },
                        {
                            coinImg: "https://upload.wikimedia.org/wikipedia/commons/thumb/4/46/Bitcoin.svg/2048px-Bitcoin.svg.png",
                            coinName: "DOGE",
                            coinPrice: "33 994,10 USD",
                            dots: 4,
                            status: "sell",
                            profit: "-12,4%",
                            stockExchange: "Binance",
                            buyingTime: "12 Июля в 17:02:29",
                            sum: "0,00035121/12 USD",
                            timeFrame: "30m",
                            direction: "Медвежий",
                            bar: 5,
                            currentSignal: "Нулевой крест (Медвежий)",
                            force: "Слабый"
                        },
                        {
                            coinImg: "https://upload.wikimedia.org/wikipedia/commons/thumb/4/46/Bitcoin.svg/2048px-Bitcoin.svg.png",
                            coinName: "DOGE",
                            coinPrice: "33 994,10 USD",
                            dots: 4,
                            status: "buy",
                            profit: "-12,4%",
                            stockExchange: "Binance",
                            buyingTime: "12 Июля в 17:02:29",
                            sum: "0,00035121/12 USD",
                            timeFrame: "30m",
                            direction: "Медвежий",
                            bar: 2,
                            currentSignal: "Нулевой крест (Медвежий)",
                            force: "Слабый"
                        },{
                            coinImg: "https://upload.wikimedia.org/wikipedia/commons/thumb/4/46/Bitcoin.svg/2048px-Bitcoin.svg.png",
                            coinName: "DOGE",
                            coinPrice: "33 994,10 USD",
                            dots: 4,
                            status: "buy",
                            profit: "-12,4%",
                            stockExchange: "Binance",
                            buyingTime: "12 Июля в 17:02:29",
                            sum: "0,00035121/12 USD",
                            timeFrame: "30m",
                            direction: "Медвежий",
                            bar: 2,
                            currentSignal: "Нулевой крест (Медвежий)",
                            force: "Слабый"
                        },{
                            coinImg: "https://upload.wikimedia.org/wikipedia/commons/thumb/4/46/Bitcoin.svg/2048px-Bitcoin.svg.png",
                            coinName: "DOGE",
                            coinPrice: "33 994,10 USD",
                            dots: 4,
                            status: "buy",
                            profit: "-12,4%",
                            stockExchange: "Binance",
                            buyingTime: "12 Июля в 17:02:29",
                            sum: "0,00035121/12 USD",
                            timeFrame: "30m",
                            direction: "Медвежий",
                            bar: 2,
                            currentSignal: "Нулевой крест (Медвежий)",
                            force: "Слабый"
                        },{
                            coinImg: "https://upload.wikimedia.org/wikipedia/commons/thumb/4/46/Bitcoin.svg/2048px-Bitcoin.svg.png",
                            coinName: "DOGE",
                            coinPrice: "33 994,10 USD",
                            dots: 4,
                            status: "buy",
                            profit: "-12,4%",
                            stockExchange: "Binance",
                            buyingTime: "12 Июля в 17:02:29",
                            sum: "0,00035121/12 USD",
                            timeFrame: "30m",
                            direction: "Медвежий",
                            bar: 2,
                            currentSignal: "Нулевой крест (Медвежий)",
                            force: "Слабый"
                        },{
                            coinImg: "https://upload.wikimedia.org/wikipedia/commons/thumb/4/46/Bitcoin.svg/2048px-Bitcoin.svg.png",
                            coinName: "DOGE",
                            coinPrice: "33 994,10 USD",
                            dots: 4,
                            status: "buy",
                            profit: "-12,4%",
                            stockExchange: "Binance",
                            buyingTime: "12 Июля в 17:02:29",
                            sum: "0,00035121/12 USD",
                            timeFrame: "30m",
                            direction: "Медвежий",
                            bar: 2,
                            currentSignal: "Нулевой крест (Медвежий)",
                            force: "Слабый"
                        },{
                            coinImg: "https://upload.wikimedia.org/wikipedia/commons/thumb/4/46/Bitcoin.svg/2048px-Bitcoin.svg.png",
                            coinName: "DOGE",
                            coinPrice: "33 994,10 USD",
                            dots: 4,
                            status: "buy",
                            profit: "-12,4%",
                            stockExchange: "Binance",
                            buyingTime: "12 Июля в 17:02:29",
                            sum: "0,00035121/12 USD",
                            timeFrame: "30m",
                            direction: "Медвежий",
                            bar: 2,
                            currentSignal: "Нулевой крест (Медвежий)",
                            force: "Слабый"
                        },
                    ], pages: 1}) //TODO to delete
                commit('writeFilters', {filters: []})

            } catch (err) {
                console.log('error', err)
            }
        },
    },
    getters: {
        CARDS(state) {
            return state.cards
        },
        CARDS_PAGE_COUNT(state) {
            return state.cardsPageCount
        }
    }
}

export default DashesModule