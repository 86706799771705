export const BACKEND_ENDPOINTS = {
    login: {method: "POST", url: "auth/token/login/"},
    getSignal: {method: "GET", url: "signals/timeframes/"},
    getAllTimeFrames: {method: "GET", url: "signals/all_timeframes/"},
    signalDetails: {method: "GET", url: "signals/details/"},
    getTimeframes: {method: "GET", url: "signals/timeframe/"},
    getGeneralTimeframes: {method: "GET", url: "signals/general_timeframes/"},
    getAO: {method: "GET", url: "signals/ao_signals/"},
    getStohastic: {method: "GET", url: "signals/stoch_signals/"},
    getStohasticLine: {method: "GET", url: "signals/stoch_line/"},
    getMovingAverages: {method: "GET", url: "signals/moving_averages/"},
    calculate: {method: "POST", url: "signals/calculator/"},
    calculatesList: {method: "GET", url: "signals/calculator/"},
    calculateSave: {method: "PATCH", url: "signals/calculator/"},
    getPivots: {method: "GET", url: "signals/pivot_points/"},
    getSymbols: {method: "GET", url: "signals/symbols/"},
    getWidgetSymbols: {method: "GET", url: "signals/widget_symbols/"},
    getIndicators: {method: "GET", url: "signals/indicators/"},
    getSmiioCurrent: {method: "GET", url: "signals/smiio_current/"},
    getSmiioSignals: {method: "GET", url: "signals/smiio_signals/"},
    getSmiioResistLine: {method: "GET", url: "/signals/smiiopp/"},
    getTableIndicators: {method: "GET", url: "/signals/tableindicators/"},
    pivotSMIIOdata: {method: "GET", url: "/signals/table/"},
    ath: {method: "GET", url: "/signals/correction_table/"},
    getActions: {method: "GET", url: "/signals/coinmarket/"},
    getActionsCategories: {method: "GET", url: "/signals/coinmarket_category/"},
    getActionsCoins: {method: "GET", url: "/signals/coinmarket_coin/"},
    getBotOptions: {method: "GET", url: "/signals/trading_bot_options/"},
    setBotOptions: {method: "PUT", url: "/signals/trading_bot_options/"},
    // market: {method: "GET", url: "/signals/market/"},
    // additionalLine: {method: "GET", url: "/signals/additional_line/"},
    getExchangeList: {method: "GET", url: "/signals/exchage_list/"},
    getExchangeAccounts: {method: "GET", url: "/signals/account_list/"},
    getSpotData: {method: "GET", url: "/signals/spot/"},
    getMarginData: {method: "GET", url: "/signals/margin/"},
    getTwits: {method: "GET", url: "twitter/profiles/"},
    connectTwits: {method: "POST", url: "twitter/profiles/"},
    deleteTwits: {method: "DELETE", url: "twitter/profiles/"},
    massDeleteTwits: {method: "POST", url: "twitter/profiles_delete/"},
    getCorrespondingTwits: {method: "GET", url: "/twitter/correspondents/"},
    setCorrespondingTwits: {method: "POST", url: "/twitter/correspondents/"},
    getTelegramChannels: {method: "GET", url: "telegram_channels/telegram_channels/"},
    massDeleteChannels: {method: "POST", url: "telegram_channels/channels_delete/"},
    getCorrespondingChannel: {method: "GET", url: "/telegram_channels/correspondents/"},
    setCorrespondingChannel: {method: "POST", url: "/telegram_channels/correspondents/"},
    setIndicatorCorrespondents: {method: "POST", url: "/telegram_channels/indicator_signal_correspondents/"},
    indicatorCorrespondents: {method: "GET", url: "/telegram_channels/indicator_signal_correspondents/"},
    delExchangePosition: {method: "GET", url: "/signals/margin/bybit/"},
//    main page
    getDashboard: {method: "GET", url: "/signals/dashboard/"},
    getMoonPhase: {method: "GET", url: "/signals/moon_phase/"},
    getSmaSmiio: {method: "GET", url: "/signals/sma_smiio/"},
    getCoinMarket: {method: "GET", url: "/signals/coinmarket_dashboard/"},
//  retrotest page
    getRetroTest: {method: "GET", url: "/retrotest/parametrs/smiio_signal/"},
    putRetroTest: {method: "PUT", url: "/retrotest/parametrs/smiio_signal/"},

    getRetroData: {method: "GET", url: "/retrotest/retrotest_smiio/"},
    getRetroPNL: {method: "GET", url: "/retrotest/retrotest_smiio/by_coin/pnl/"},
    getRetroDeals: {method: "GET", url: "/retrotest/retrotest_smiio/by_coin/deals/"},
//  composite signals page
    getCompositeSignals: {method: "GET", url: "/signals/super_signals"},
}
