const Loader = {
    state: () => ({
        loader: false
    }),
    mutations: {
        writeLoader(state, payload) {
            state.loader = payload
        }
    },
    actions: {
        SET_LOADER({ commit }, payload) {
            commit("writeLoader", payload)
        }
    },

    getters: {
        GET_LOADER(state) {
            return state.loader
        }
    }
}

export default Loader