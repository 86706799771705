import Vue from "vue";
import Vuex from "vuex";
import AuthModule from "./modules/auth"
import SignalsModule from "./modules/signals"
import FiltersModule from "./modules/filters"
import TwitsModule from "./modules/twits"
import PageTitleModule from "./modules/pageTitle"
import TelegramModule from "./modules/telegram"
import DashesModule from "./modules/dashes"
import Loader from "./modules/loader"
import Calculator from "./modules/calculator"
import TimeframesModule from "./modules/timeframes"
import MenuModule from "./modules/menu"
import ATHModule from "./modules/ath"
import ActionsModule from "./modules/actionsModule"
import BotSettings from "./modules/botSettings";
import MarketModule from "./modules/market";
import Exchanges from "./modules/exchanges";
import RefreshModule from "./modules/refresh"
import CombinedModule from "./modules/combinedSignals";
import MainModule from "./modules/mainPage";
import RetroTest from "./modules/RetroTest";
import OffsetModule from "./modules/offset";
import SupperModule from "./modules/superSignals";

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        AuthModule,
        SignalsModule,
        FiltersModule,
        TwitsModule,
        PageTitleModule,
        TelegramModule,
        DashesModule,
        Loader,
        Calculator,
        TimeframesModule,
        MenuModule,
        ATHModule,
        ActionsModule,
        BotSettings,
        MarketModule,
        Exchanges,
        RefreshModule,
        CombinedModule,
        MainModule,
        RetroTest,
        OffsetModule,
        SupperModule,
    },
});
