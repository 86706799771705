import api from "@/api"
import dateFormatter from "../../formatters/dateFormatter";
// import {serializeTimeframe} from "../../serializer/timeframeSerializer";


const ActionsModule = {
    state: () => ({
        categories: [],
        actionsData: [],
        coins: [],
        totalPages: 0
    }),
    mutations: {
        writeAnswer(state, payload) {
            state[payload.to] = payload.data
        },
    },
    actions: {
        async getCategories({commit}) {
            try {
                const data = await api.getActionsCategories()
                commit('writeAnswer', {to: "categories", data: data.results})
            } catch (err) {
                console.log('error', err)
            }
        },
        async getCoins({commit}) {
            try {
                const data = await api.getActionsCoins()
                commit('writeAnswer', {to: "coins", data: data.results})
            } catch (err) {
                console.log('error', err)
            }
        },
        async getActions({commit}, payload) {
            let filters = ''
            if (payload) filters = Object.keys(payload).map(el => {
                return `${el}=${payload[el]}`
            }).join("&")
            // const _filter = payload ? `?${Object.keys(payload)[0]}=${payload[Object.keys(payload)[0]]}` : ''
            try {
                const data = await api.getActions({args: `?${filters}`})
                data.results.map(el => {
                    el.symbols = el.coins.map(item => {
                        return item.bybit.symbol
                    }).join(', ')
                    el.categoriesString = el.categories.map(item => {
                        return item.name
                    }).join(', ')
                    el.ranks = el.coins.map(item => {
                        return item.rank
                    }).join(', ')
                    el.tagsString = el.tags.join(', ')
                    el.date_event = dateFormatter(el.date_event, false, false)
                    el.created_date = dateFormatter(el.created_date, false, false)
                    return el
                })
                commit('writeAnswer', {to: "actionsData", data: data.results})
                commit('writeAnswer', {to: "totalPages", data: Math.ceil(data.count/25)})
            } catch (err) {
                console.log('error', err)
            }
        },
    },
    getters: {
        GET_ACTIONS_CATEGORIES(state) {
            return [{name: "Все категории", value: "allCat", id: 0}, ...state.categories.map(el => {
                el.value = el.name
                return el
            })]
        },
        GET_ACTIONS_DATA(state) {
            return state.actionsData
        },
        GET_ACTIONS_DATA_TOTAL_PAGES(state) {
            return state.totalPages
        },
        COINS(state) {
            return state.coins.map(el => {
                const newCoin = {}
                newCoin.id = el.id
                newCoin.symbol = el.bybit.symbol
                return newCoin
            })
        },
    }
}

export default ActionsModule
