import moment from "moment";

const dateFormatter = (date, showFulltime= false, fullMonth=true, timeOnly = false) => {
    if (date) {
        let format = showFulltime ? 'DD MMMM YYYY HH:mm:ss' : fullMonth ? 'DD MMMM YYYY' : timeOnly ? 'HH:mm:ss' : 'DD.MM.YYYY'
        let time = moment(date, 'X', true).locale("ru")
        return time.format(format)
    } else return null
}

export default dateFormatter