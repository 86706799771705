import api from "@/api"

const FiltersModule = {
    state: () => ({
        symbols: [],
        listForMain: [],
        widgetSymbols: []
    }),
    mutations: {
        writeFilters(state, payload) {
            state[Object.keys(payload)[0]] = payload[Object.keys(payload)[0]]
        }
    },
    actions: {
        async getSYMBOLS({commit}) {
            try {
                const data = await api.getSymbols()
                commit('writeFilters', {symbols: data.results})
                commit('writeFilters', {listForMain: [...data.results].slice(0, 4)})
            } catch (err) {
                console.log('error', err)
            }
        },
        async getWIDGET_SYMBOLS({commit}) {
            try {
                const data = await api.getWidgetSymbols()
                commit('writeFilters', {widgetSymbols: data.results})
            } catch (err) {
                console.log('error', err)
            }
        },
        changeCoin({state, commit}, {index, coin}) {
            if (coin) {
                const newList = [...state.listForMain]
                newList[index]=coin
                commit('writeFilters', {listForMain: newList})
            }
        }
    },
    getters: {
        SYMBOLS(state) {
            return state.symbols
        },
        WIDGET_SYMBOLS(state) {
            return state.widgetSymbols
        },
        SY_LIST() {
            // let mapped = state.listForMain?.map(el => {
            //     return {symbol: el.symbol.split("/").join(""), isChange: true}
            // })
            let mapped = []
            mapped.push({symbol: 'CRYPTOCAP:TOTAL', isChange: false})
            mapped.push({symbol: 'CRYPTOCAP:BTC.D', isChange: false})
            mapped.push({symbol: 'BTCUSDT', isChange: false})
            mapped.push({symbol: 'ETHUSDT', isChange: false})
            return mapped
        }
    }
}

export default FiltersModule
