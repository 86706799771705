import api from "@/api"
import Toast from "../../components/Toast/Toast";
import Vue from "vue";

const BotSettings = {
    state: () => ({
        settings: [],
    }),
    mutations: {
        writeAnswer(state, payload) {
            state[payload.to] = payload.data
        },
    },
    actions: {
        async getBOT_SETTINGS({commit}) {
            const data = await api.getBotOptions()
            data.results = data.results.map(el => {
                if (Object.keys(el).includes('no_longs') && Object.keys(el).includes('no_shorts')) {
                    el.no_longs = {status: el.no_longs, value: true}
                    el.no_shorts = {status: el.no_shorts, value: true}
                }
                el.title = el.name || "bot " + el.id
                el.value = el.id
                return el
            })
            commit('writeAnswer', {to: "settings", data: data.results})
        },
        async setBOT_SETTINGS({commit}, {data, args}) {
            const result = await api.setBotOptions({data: data, args: `${args}/`})
            if (result.Success) {
                const content = {
                    component: Toast,
                    props: {
                        toastProps: {
                            title: `Данные бота "${data.name}" успешно сохранены`,
                            content: "Success"
                        }
                    },
                };
                Vue.$toast(content)
            }
            const newVal = {...result.data}
            newVal.signals_exclude_tf ? newVal.timeframes = newVal.signals_exclude_tf.split(", ") : ''

            commit('writeAnswer', {to: "settings", data: newVal})
        },
    },
    getters: {
        BOT_SETTINGS(state) {
            return state.settings
        },
    }
}
export default BotSettings
