import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "../store";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/cabinet',
  },
  {
    path: '/cabinet',
    component: () => import('../views/Cabinet.vue'),
    redirect: '/cabinet/main',
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: 'main',
        name: 'Main',
        component: () => import('../views/Main.vue'),
      },
      // {
      //   path: 'signals',
      //   name: 'Signals',
      //   component: () => import('../views/Signals.vue'),
      // },
      // {
      //   path: 'twitter',
      //   name: 'Trwitter',
      //   component: () => import('../views/Twitter.vue'),
      // },
      // {
      //   path: 'telegram',
      //   name: 'Telegram',
      //   component: () => import('../views/Telegram.vue'),
      // },
      // {
      //   path: 'dashboard',
      //   name: 'Dashboard',
      //   component: () => import('../views/Dashboard.vue'),
      // },
      // {
      //   path: 'pivot-tables',
      //   name: 'PivotTables',
      //   component: () => import('../views/PivotTables.vue'),
      // },
      {
        path: 'ath',
        name: 'ATH',
        component: () => import('../views/ATH.vue'),
      },
      {
        path: 'events',
        name: 'Actions',
        component: () => import('../views/Actions.vue'),
      },
      // {
      //   path: 'spot',
      //   name: 'Spot',
      //   component: () => import('../views/Spot.vue'),
      // },
      {
        path: 'direvatives',
        name: 'Direvatives',
        component: () => import('../views/Direvatives.vue'),
      },
      {
        path: 'smiio-signals',
        name: 'SmiioSignals',
        component: () => import('../views/SmiioSignals.vue'),
      },
      {
        path: 'retro-test',
        name: 'RetroTest',
        component: () => import('../views/RetroTest.vue'),
      },
      {
        path: 'super-signals',
        name: 'SuperSignals',
        component: () => import('../views/SuperSignals.vue'),
      },
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isAuthorized) {
      next()
      return
    }
    next('/login')
  } else {
    if (store.getters.isAuthorized && to.path==='/login') {
      next('/cabinet/signals')
      return;
    }
    next()
  }
})

export default router
