import api from "@/api"


const AuthModule = {
    state: () => ({
        isAuth: !!localStorage.getItem('tAccess'),
        token: ""
    }),
    mutations: {
        auth(state, payload) {
            state.token = payload.token;
            if (state.token?.length > 0) state.isAuth = true
        }
    },
    actions: {
        async LOGIN({commit}, payload) {
            try {
                const data = await api.login({data: payload})
                localStorage.setItem('tAccess', data.auth_token)
                commit('auth', {token: data.auth_token})
            } catch (err) {
                console.log('error', err)
            }
        },
    },
    getters: {
        isAuthorized(state) {
            return state.isAuth
        },
        MESSAGE(state) {
            return state.message
        }
    }
}

export default AuthModule
