import api from "@/api"
import dateFormatter from "../../formatters/dateFormatter";

const Calculator = {
    state: () => ({
        order: {name: "4", value: "4"},
        step: {name: "Обычная", value: "normal"},
        priceStep: {name: "1,00", value: "2"},
        quantityStep: {name: "1,00", value: "2"},
        amount: "",
        lowPrice: "",
        upPrice: "",
        showCalculator: false,
        results: {},
        coin: null,
        orderType: {name: "Покупка", value: "1"},
        calculatesList: [],
        calculatesCount: 1,
        canSave: false,

        orderTypes: [
            {name: "Покупка", value: "1"},
            {name: "Продажа", value: "2"}
        ],
        orders: [
            {name: "4", value: "4"},
            {name: "6", value: "6"},
            {name: "8", value: "8"},
            {name: "10", value: "10"}
            ],
        steps: [{name: "Обычная", value: "normal"}, {name: "Нарастающая", value: "cumulative"}],
        priceSteps: [
            {name: "1", value: "0"},
            {name: "1,0", value: "1"},
            {name: "1,00", value: "2"},
            {name: "1,000", value: "3"},
            {name: "1,0000", value: "4"},
        ],
        quantitySteps: [
            {name: "1", value: "0"},
            {name: "1,0", value: "1"},
            {name: "1,00", value: "2"},
            {name: "1,000", value: "3"},
            {name: "1,0000", value: "4"},
        ],
    }),
    mutations: {
        writeCalc(state, {to, data}) {
            state[to] = data
        },
        pushCalc(state, {to, data}) {
            state[to].push(...data)
        }
    },

    actions: {
        SET_CALC_DATA({ commit }, payload) {
            commit("writeCalc", payload)
        },

        async ASK_CALCULATES({state, commit}, payload) {
            const {page, clear} = payload
            if (clear) {
                commit("writeCalc", {to: "calculatesList", data: []})
            } else if (page <= state.calculatesCount) {
                const answer = await api.calculatesList({args: `?ordering=-id&page=${page}`})
                answer.Success = true
                if (answer.Success) {
                    commit("pushCalc", {to: "calculatesList", data: answer.results})
                    commit("writeCalc", {to: "calculatesCount", data: Math.ceil(answer.count/10)})
                } else {
                    commit("writeCalc", {to: "calculatesList", data: []})
                    commit("writeCalc", {to: "calculatesCount", data: 1})
                }
            }
        },

        async ASK_CALCULATE_BY_ID({state, commit}, payload) {
            const {id} = payload
            const answer = await api.calculatesList({args: `${id}/`})
            answer.Success = true
            if (answer.Success) {
                commit("writeCalc", {to: "results", data: answer.data})

                commit("writeCalc", {to: "amount", data: answer.data.amount.toString()})
                commit("writeCalc", {to: "upPrice", data: answer.data.max_price.toString()})
                commit("writeCalc", {to: "lowPrice", data: answer.data.min_price.toString()})
                commit("writeCalc", {to: "coin", data: answer.data.symbol})
                commit("writeCalc", {to: "order", data: state.orders.find(el => el.value===answer.data.orders.toString())})
                commit("writeCalc", {to: "step", data: state.steps.find(el => el.value===answer.data.ladder)})
                commit("writeCalc", {to: "priceStep", data: state.priceSteps.find(el => el.name===answer.data.round_price.replace(".", ","))})
                commit("writeCalc", {to: "quantityStep", data: state.quantitySteps.find(el => el.name===answer.data.round_count.replace(".", ","))})
                commit("writeCalc", {to: "orderType", data: state.orderTypes.find(el => el.name===answer.data.type)})
            } else {
                commit("writeCalc", {to: "results", data: {}})
            }
        },

        async CALCULATE({ state, commit }) {
            const initial = {
                "amount": state.amount.replace(",", "."),
                "max_price": state.upPrice.replace(",", "."),
                "min_price": state.lowPrice.replace(",", "."),
                "orders": state.order.value.toString(),
                "ladder": state.step.value,
                "round_price": state.priceStep.name.replace(",", "."),
                "round_count": state.quantityStep.name.replace(",", "."),
                "symbol": state.coin.id,
                "type": state.orderType.name,
                "stamp": 0
            }
            const answer = await api.calculate({data: initial})
            if (answer.Success) {
                commit("writeCalc", {to: "results", data: answer.data})
            } else {
                commit("writeCalc", {to: "results", data: {}})
            }
        },
        async SAVE_CALCULATE(context, payload) {
            const { id } = payload
            const initial = {
                "is_to_save": true
            }
            const answer = await api.calculateSave({data: initial, args: `${id}/`})
            if (!answer.Success) {
                console.log("Can't save data")
            }
        }
    },

    getters: {
        GET_ORDER(state) {
            return state.order
        },
        GET_ORDER_TYPE(state) {
            return state.orderType
        },
        GET_STEP(state) {
            return state.step
        },
        GET_PRICE_STEP(state) {
            return state.priceStep
        },
        GET_QUANTITY_STEP(state) {
            return state.quantityStep
        },
        GET_AMOUNT(state) {
            return state.amount
        },
        GET_UP_PRICE(state) {
            return state.upPrice
        },
        GET_LOW_PRICE(state) {
            return state.lowPrice
        },
        GET_SHOW_CALCULATOR(state) {
            return state.showCalculator
        },
        GET_RESULTS_OF_CALCULATION(state) {
            return state.results?.table
        },
        GET_RESULT_PRICE(state) {
            return state.results?.table_total
        },
        GET_RESULT_DATE(state) {
            return dateFormatter(state.results?.stamp)
        },
        GET_RESULT_ID(state) {
            return state.results.id
        },
        GET_COIN(state) {
            return state.coin
        },
        GET_CALCULATES(state) {
            return state.calculatesList
        },
        /**
         * @return {boolean}
         */
        CAN_SAVE(state) {
            return state.canSave
        },
        GET_ORDERS(state) {
            return state.orders
        },
        GET_ORDER_TYPES(state) {
            return state.orderTypes
        },
        GET_STEPS(state) {
            return state.steps
        },
        GET_PRICE_STEPS(state) {
            return state.priceSteps
        },
        GET_QUANTITY_STEPS(state) {
            return state.quantitySteps
        },
        /**
         * @return {number}
         */
        GET_CALCULATES_PAGES_COUNT(state) {
            return state.calculatesCount
        },
    }
}

export default Calculator