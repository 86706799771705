import api from "@/api"
import {serializeTimeframe} from "../../serializer/timeframeSerializer";

const SignalsModule = {
    state: () => ({
        signals: [],
        signalDetails: [],
        signalsPageCount: 0,
        AO: [],
        maData: [],
        // maDataValues: [],
        indicators: [],
        timeframesSrsi: [],
        stohastic: [],
        stohasticLine: [],
        // pivotPoints: [],
        fibonacciPoints: [],
        smiio: [],
        smiioResistLine: [],
        pivotSMIIOdata: [],
    }),
    mutations: {
        writeAnswer(state, payload) {
            state[payload.to] = payload.data
        },
        clearPivotSmiioData(state) {
            state.pivotSMIIOdata.length = 0
        },
        addToPivotSmiioData(state, payload) {
            state.pivotSMIIOdata.push(payload)
        }
    },
    actions: {
        async getSIGNALS({commit}, payload) {
            const {filter, page} = payload
            const _filter = filter ? `symbol=${filter.length > 1 ? filter.join(",") : filter}&` : ""
            try {
                const data = await api.getSignal({args: `?${_filter}ordering=-time&page=${page}`})
                const pages = Math.ceil(data.count / 10)
                data.results.map(el => {
                    let obj = {}
                    Object.assign(obj, {['value']: el.timeframe['id']});
                    Object.assign(obj, {['name']: el.timeframe['timeframe']});
                    Object.assign(obj, {['nameRu']: el.timeframe['timeframe_ru']})
                    el.timeframe = obj
                    return el.timeframe
                })
                commit('writeAnswer', {to: "signals", data: data.results})
                commit('writeAnswer', {to: "signalsPageCount", data: pages})
                commit('writeTitle', "Сигналы")
            } catch (err) {
                console.log('error', err)
            }
        },
        async getSIGNAL_DETAILS({commit}, {signalId}) {
            commit("writeAnswer", {to: "signalDetails", data: []})
            const data = await api.signalDetails({args: `${signalId}/`})
            commit("writeAnswer", {to: "signalDetails", data: data.results})
        },
        async getINDICATORS({commit}) {
            const data = await api.getIndicators({args: "?ordering=id"})
            commit('writeAnswer', {to: "indicators", data: data.results})
        },
        // async getPIVOTS({commit}, payload) {
        //     const {symbol, timeframe, label, fibonachi} = payload
        //     let _filter = ""
        //     symbol ? _filter = `?${_filter}symbol=${symbol}&` : ""
        //     timeframe ? _filter = `${_filter}timeframe=${timeframe}&` : ""
        //     label ? _filter = `${_filter}label=${label}` : ""
        //     fibonachi ? _filter = `${_filter}&fibonacci` : ""
        //     const data = await api.getPivots({args: `${_filter}&ordering=order`})
        //     const {fibonacci, pivots} = data.results
        //     if (!fibonacci) {
        //         data.results.map(el => {
        //             el.timeframe = serializeTimeframe(el.timeframe)
        //             return el.timeframe
        //         })
        //     } else {
        //         pivots.map(el => {
        //             el.timeframe = serializeTimeframe(el.timeframe)
        //             return el.timeframe
        //         })
        //         fibonacci.map(el => {
        //             el.timeframe = serializeTimeframe(el.timeframe)
        //             return el.timeframe
        //         })
        //     }
        //     commit('writeAnswer', {to: "pivotPoints", data: !fibonacci ? data.results : pivots})
        //     commit('writeAnswer', {to: "fibonacciPoints", data: fibonacci ? fibonacci : []})
        // },
        async getSMIIO_RESIST_LINE({commit}, payload) {
            const {symbol, label} = payload
            let _filter = ""
            symbol ? _filter = `?${_filter}symbol=${symbol}&` : ""
            label ? _filter = `${_filter}label=${label}` : ""
            const data = await api.getSmiioResistLine({args: `${_filter}&ordering=order`})
            commit('writeAnswer', {to: "smiioResistLine", data: data})
        },
        async getMOVING_AVEREGES({commit}, payload) {
            const {symbol, timeframe, label} = payload
            let _filter = ""
            symbol ? _filter = `${_filter}?symbol=${symbol}` : ""
            timeframe ? _filter = `${_filter}&timeframe=${timeframe}` : ""
            label ? _filter = `${_filter}&label=${label}` : ""
            const data = await api.getMovingAverages({args: `${_filter}&ordering=order`})
            // let _filterValues = ""
            // symbol ? _filterValues=`${_filterValues}?symbol=${symbol}` : ""
            // label ? _filterValues=`${_filterValues}&label=${label}` : ""
            // const dataValues = await api.getMovingAverages({args: `${_filterValues}&ordering=order`})
            commit('writeAnswer', {to: "maData", data: data.results})
            // commit('writeAnswer', {to: "maDataValues", data: dataValues.results})
        },
        async getSTOHASTIC_SIGNALS({commit}, payload) {
            const {symbol, timeframe} = payload
            let _filter = ""
            symbol ? _filter = `${_filter}?symbol=${symbol}` : ""
            timeframe ? _filter = `${_filter}&timeframe=${timeframe}` : ""
            // stohastic signals
            const data = await api.getStohastic({args: `${_filter}&ordering=order`})
            data.results.map(el => {
                el.timeframe = serializeTimeframe(el.timeframe)
                return el.timeframe
            })
            commit('writeAnswer', {to: "stohastic", data: data.results})
        },
        async getSTOHASTIC_LINE({commit}, payload) {
            const {symbol, timeframe} = payload
            let _filter = ""
            symbol ? _filter = `${_filter}?symbol=${symbol}` : ""
            timeframe ? _filter = `${_filter}&timeframe=${timeframe}` : ""
            // stohastic signals
            const data = await api.getStohasticLine({args: `${_filter}&ordering=order`})
            commit('writeAnswer', {to: "stohasticLine", data: data.results})
        },
        async getAO_SIGNALS({commit}, payload) {
            // signal_time
            const {symbol} = payload
            const _filter = symbol ? `?symbol=${symbol}` : ""
            try {
                const data = await api.getAO({args: `${_filter}&ordering=order`})
                // const pages = Math.ceil(data.count / 50)
                data.results.map(el => {
                    el.timeframe = serializeTimeframe(el.timeframe)
                    return el.timeframe
                })
                commit('writeAnswer', {to: "AO", data: data.results})
                // commit('writeTitle', data.results.title)
            } catch (err) {
                console.log('error', err)
            }
        },
        async getSMIIO({commit}, payload) {
            const {symbol, label} = payload
            let _filter = ""
            symbol ? _filter = `${_filter}?symbol=${symbol}&ordering=order` : ""
            const l = label.match('Current') || label.match('Forecast') ? 'Current' : label
            const schema = "getSmiio" + l
            // timeframe ? _filter=`${_filter}&timeframe=${timeframe}` : ""
            const data = await api[schema]({args: `${_filter}`})
            data.results.map(el => {
                el.timeframe = serializeTimeframe(el.timeframe)
                return el.timeframe
            })
            commit('writeAnswer', {to: "smiio", data: data.results})
        },
        async getTableIndicators({commit}, {symbol}) {
            let _filter = "?"
            symbol ? _filter = `${_filter}symbol=${symbol}` : ""
            const data = await api.getTableIndicators({args: _filter})
            commit('writeAnswer', {to: "pivotSMIIOdata", data: data})
        },
        async getPivotSMIIOData({commit}, {symbol, label, timeframe}) {
            let _filter = ""
            symbol ? _filter = `${_filter}?symbol=${symbol}` : ""
            label ? _filter = `${_filter}&label=${symbol}` : ""
            commit('clearPivotSmiioData')
            const answer = await api.pivotSMIIOdata({args: `${_filter}&timeframe=${timeframe}&ordering=order`})
            commit('addToPivotSmiioData', answer[0])
        }
    },
    getters: {
        GET_PIVOTS_SMIIO_DATA(state) {
            return state.pivotSMIIOdata.filter(e => e.indicator)
        },
        SMIIO(state) {
            return state.smiio
        },
        SIGNALS(state) {
            return state.signals
        },
        SIGNAL_DETAILS(state) {
            return state.signalDetails
        },
        AO_SIGNALS(state) {
            return state.AO
        },
        SIGNALS_PAGE_COUNT(state) {
            return state.signalsPageCount
        },
        // PIVOT_POINTS(state) {
        //     return state.pivotPoints
        // },
        FIBONACCI_POINTS(state) {
            return state.fibonacciPoints
        },
        INDICATORS(state) {
            let arr = state.indicators.sort((a, b) => a.id - b.id)
            return arr.map(el => {
                const newObject = {};
                delete Object.assign(newObject, el, {['value']: el['id']})['id'];
                delete Object.assign(newObject, el, {['name']: el['indicator_name']})['indicator_name'];
                return newObject
            })
        },
        STOHASTIC_SIGNALS(state) {
            const dictionary = {
                motion: {
                    title: 'Движение',
                    k: 3,
                    d: 6,
                },
                value: {
                    title: 'Значение',
                    k: 2,
                    d: 5,
                },
                zone: {
                    title: 'Зона',
                    k: 4,
                    d: 7,
                },
            }
            const result = {
                headers: [],
                values: state.stohastic,
            }
            if (state.stohastic && state.stohastic.length > 0) {
                result.headers = Object.keys(state.stohastic[0]).map(el => {
                    const title = el.split('_')
                    if (title[1]) return {
                        order: dictionary[title[0]][title[1]],
                        id: el,
                        title: `${dictionary[title[0]].title} ${title[0].match('value') ? title[1].toUpperCase() : ''}`
                    }
                    return null
                }).filter(element => element != null)
            }
            return result
        },
        STOHASTIC_LINE(state) {
            return state.stohasticLine
        },
        MOVING_AVERAGES(state) {
            const data = state.maData[0]?.cross
            if (data) {
                const result = {
                    headers: [],
                    values: [],
                }
                result.headers = Object.keys(data).map(el => {
                    return {
                        id: el,
                        title: el.replace(/[^0-9]/g, ""),
                    }
                }).sort((a, b) => a.title - b.title);
                result.values = state.maData
                return result
            }
        },
        SMIO(state) {
            return state.maData
        }
    }
}

export default SignalsModule
