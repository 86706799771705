import api from "@/api"
// import {serializeTimeframe} from "../../serializer/timeframeSerializer";

const ATHModule = {
    state: () => ({
        ath: {}
    }),
    mutations: {
        writeAnswer(state, payload) {
            state[payload.to] = payload.data
        },
    },
    actions: {
        async getATH({commit}) {
            try {
                const data = await api.ath()
                //TODO a crutch that needs to be removed when they do it humanly on the back
                data.results.groups.sort(function (a, b) {
                    if (a.group > b.group) {
                        return 1;
                    }
                    if (a.group < b.group) {
                        return -1;
                    }
                    // a должно быть равным b
                    return 0;
                })
                //
                commit('writeAnswer', {to: "ath", data: data.results})
            } catch (err) {
                console.log('error', err)
            }
        },
    },
    getters: {
        GET_ATH(state) {
            if (state.ath instanceof Object) return state.ath; else return {}
        },
    }
}

export default ATHModule
