import Axios from "axios"
import {BACKEND_ENDPOINTS} from "./api.config"
import Vue from "vue";
import Toast from "../components/Toast/Toast";

class ApiClientClass {
    constructor(options = {}) {
        this.defaultHeaders = options.headers || {
            "Accept": "application/json",
            "Content-Type": "application/json",
        }
        // this.client.defaultHeaders.Authorization = new AuthenticationHeaderValue("Basic", "encrypted user/pwd")
        this.client = options.client || Axios.create({
            baseURL: process.env.VUE_APP_API_URL ? process.env.VUE_APP_API_URL : '',
            headers: this.defaultHeaders
        });

        this.client.interceptors.request.use(
            config => {
                if (!localStorage.getItem('tAccess')) {
                    return config
                }
                const newHeadres = {
                    ...this.defaultHeaders,
                    Authorization: `Token ${localStorage.getItem('tAccess')}`
                }
                return {
                    ...config,
                    headers: newHeadres
                }
            },
            e => Promise.reject(e)
        )
    }


    urlFormat(url, args) {
        return `${url}${args.length > 0 ? args : ''}`
    }
}

export default new Proxy(
    new ApiClientClass(),
    {
        get: function (target, name) {
            if (BACKEND_ENDPOINTS[name] !== undefined) {
                return ({params = {}, data = {}, args = {}} = {}) => {
                    return target.client({
                        method: BACKEND_ENDPOINTS[name].method,
                        url: target.urlFormat(BACKEND_ENDPOINTS[name].url, args),
                        data: data,
                        params: params
                    })
                        .then((serverResponse) => {
                            if (serverResponse.status === 200 && (!serverResponse.data?.Errors || serverResponse.data?.Errors.length === 0)) {
                                return serverResponse.data
                            } else {
                                serverResponse.data.Errors.forEach(el => {
                                    const content = {
                                        component: Toast,
                                        props: {
                                            toastProps: {
                                                title: "Ошибка",
                                                content: el
                                            }
                                        },
                                        listeners: {
                                            click: () => console.log("Clicked!"),
                                        }
                                    };
                                    Vue.$toast(content)
                                })
                                return serverResponse.data
                            }
                        })
                        .catch((error) => {
                            if (error.response.status===400) {
                                // Request made and server responded
                                const response =
                                    Object.keys(error.response.data).map(key => {
                                        return error.response.data[key].map(el => {
                                            return `${el} ${error.response.config.url.split("?")[0]}`
                                        })
                                    }).flat()
                                response.forEach(el => {
                                    const content = {
                                        component: Toast,
                                        props: {
                                            toastProps: {
                                                title: `${error.response.status} ${error.response.statusText}`,
                                                content: el
                                            }
                                        },
                                        listeners: {
                                            click: () => console.log("Clicked!"),
                                        }
                                    };
                                    Vue.$toast(content)
                                })
                            } else if (error.response.status!==400) {
                                const content = {
                                    component: Toast,
                                    props: {
                                        toastProps: {
                                            title: `${error.response.status} ${error.response.statusText}`,
                                            content: error.response.config.url
                                        }
                                    },
                                    listeners: {
                                        click: () => console.log("Clicked!"),
                                    }
                                };
                                Vue.$toast(content)
                            } else if (error.request) {
                                // The request was made but no response was received
                                console.log(error.request);
                            } else {
                                // Something happened in setting up the request that triggered an Error
                                console.log('Error', error.message);
                            }
                            // throw new Error('server response error:' + error)
                            return Promise.reject(error);
                        })
                }
            } else {
                return target[name]
            }
        }
    }
)
