import api from "@/api"

const MarketModule = {
    state: () => ({
        market: {
            data: [],
            headers: [],
        },
    }),
    mutations: {
        writeMarkets(state, {market}) {
            state.market = market
        },
    },
    actions: {
        async getMARKET({commit}) {
            try {
                const {result} = await api.getCoinMarket()
                const headers = [
                    {title: '', key: 'date'},
                    {title: '', key: 'symbol'},
                    {title: '', key: 'title'},
                ]
                commit('writeMarkets', {market: {data: result, headers}})
            } catch (err) {
                console.log('error', err)
            }

        },
    },
    getters: {
        COIN_MARKET(state) {
            return state.market
        },
    }
}

export default MarketModule
