import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import toasts from "vue-toastification";
import VModal from "vue-js-modal"

import './assets/fonts/Geometria/stylesheet.css';
import "./assets/toasts/index.scss";
import "./styles/index.scss";


Vue.config.productionTip = false

const options = {
  hideProgressBar: true,
  icon: false,
  closeButton: false,
};


Vue.use(toasts, options);
Vue.use(VModal, { dynamicDefaults: { draggable: true, resizable: false, height: 'auto', width: '979px' } })

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
