const OffsetModule = {
    state: () => ({
        offsetList: [
                {value: '1h', name: 'Сигналы 1h', onPage: ['smiio-signals', 'retro-test']},
                {value: '2h', name: 'Сигналы 2h', onPage: ['smiio-signals', 'retro-test']},
                {value: '3h', name: 'Сигналы 3h', onPage: ['smiio-signals', 'retro-test']},
                {value: '4h', name: 'Сигналы 4h', onPage: ['smiio-signals', 'retro-test']},
                {value: 'MTF', name: 'Сигналы MTF', onPage: ['retro-test']},
            ]
    }),
    mutations: {
    },
    actions: {
    },
    getters: {
        GET_OFFSET(state) {
            return state.offsetList;
        },
    }
}

export default OffsetModule
