import api from "@/api"
import {serializeTimeframe} from "../../serializer/timeframeSerializer";

const TimeframesModule = {
    state: () => ({
        timeframes: [],
        allTimeframes: [],
        generalTimeframes: []
    }),
    mutations: {
        writeAnswer(state, payload) {
            state[payload.to] = payload.data
        },
    },
    actions: {
        async getTIMEFRAMES({commit}) {
            const data = await api.getTimeframes({args: "?ordering=order"})
            commit('writeAnswer', {to: "timeframes", data: data.results})
        },
        async get_ALL_TIMEFRAMES({commit}) {
            const data = await api.getAllTimeFrames({args: "?ordering=order"})
            commit('writeAnswer', {to: "allTimeframes", data: data.results})
        },
        async get_GENERAL_TIMEFRAMES({commit}) {
            const data = await api.getGeneralTimeframes({args: "?ordering=order"})
            commit('writeAnswer', {to: "generalTimeframes", data: data.results})
        },
    },
    getters: {
        TIMEFRAMES(state) {
            return state.timeframes.map(el => {
                return serializeTimeframe(el)
            })
        },
        ALLTIMEFRAMES(state) {
            return state.allTimeframes.map(el => {
                return serializeTimeframe(el)
            })
        },
        GENERAL_TIMEFRAMES(state) {
            return state.generalTimeframes.map(el => {
                return serializeTimeframe(el)
            })
        }
    }
}
export default TimeframesModule