<template>
  <div
      :class="['toast']"
      @click="clickHandler"
  >
    <div
        class="btn"
        v-if="!!closeButton"
        @click.stop="clickHandler"
    >
      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41L12.59 0Z"
              fill="#E28C0B"/>
      </svg>
    </div>

    <div class="toast-body">
      <p v-if="title" class="title">{{title}}</p>
      <p v-if="content" class="content">{{content}}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Toast",
  props: ["toastProps"],
  computed: {
    closeButton() {
      return this.toastProps?.closeButton || true
    },
    title() {
      return this.toastProps?.title || "500 Internal Server Error"
    },
    content() {
      return this.toastProps?.content || "на сервере произошла внутренняя непредвиденная отказ."
    },
  },
  methods: {
    clickHandler() {
      this.$emit('close-toast')
    }
  },
}
</script>

<style lang="scss">
.toast {
  background-color: $yellow-middle;
  width: 420px;
  height: 86px;
  border-radius: 15px;
  padding: 16px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  column-gap: 16px;
  &-body {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    height: 100%;
  }
  & .btn {
    padding: 5px;
    display: flex;
    cursor: pointer;
  }
  & p {
    margin: 0;
    color: $grayscale-middle;
    font-style: normal;
    font-size: 14px;
  }
  & .title {
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.0027em;
  }
  & .content {
    font-weight: 400;
    line-height: 16px;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
  }
}
</style>