import api from "@/api"
// import Toast from "../../components/Toast/Toast";
// import Vue from "vue";

const Exchanges = {
    state: () => ({
        exchanges: [],
        exchangeAccounts: [],
        spot: {},
        margin: {}
    }),
    mutations: {
        writeAnswer(state, payload) {
            state[payload.to] = payload.data
        },
    },
    actions: {
        async delEXCHANGE_POSITION(context, payload) {
            const data = await api.delExchangePosition({args: `${payload}/`})
            return new Promise(resolve => resolve(data))
        },
        async getEXCHANGE_LIST({commit}) {
            const data = await api.getExchangeList()
            commit('writeAnswer', {to: "exchanges", data: data.results})
        },
        async getEXCHANGE_ACCOUNTS({commit}, payload) {
            const data = await api.getExchangeAccounts({args: `${payload}/`})
            commit('writeAnswer', {to: "exchangeAccounts", data: data.results})
        },
        async getSPOT_DATA({commit}, payload) {
            const data = await api.getSpotData({args: `${payload}`})
            commit('writeAnswer', {to: "spot", data: data.results})
        },
        async getMARGIN_DATA({commit}, payload) {
            const data = await api.getMarginData({args: `${payload}`})
            commit('writeAnswer', {to: "margin", data: data.results})
        },
    },
    getters: {
        EXCHANGE_SPOT_LIST(state) {
            const result = state.exchanges.map(el => {
                const item = {}
                item.id = el.id
                item.title = el.name
                item.value = el.alias
                item.type = el.account_type
                return item
            })
            return result.filter(el => el.type.includes('spot'))
        },
        EXCHANGE_MARGIN_LIST(state) {
            const result = state.exchanges.map(el => {
                const item = {}
                item.id = el.id
                item.title = el.name
                item.value = el.alias
                item.type = el.account_type
                return item
            })
            return result.filter(el => el.type.includes('margin'))
        },
        EXCHANGE_SPOT_ACCOUNTS(state) {
            return state.exchangeAccounts.map(el => {
                el.title = el.name
                el.value = el.account_id
                return el
            }).filter(el => el.account_type.includes('spot'))
        },
        EXCHANGE_MARGIN_ACCOUNTS(state) {
            return state.exchangeAccounts.map(el => {
                el.title = el.name
                el.value = el.account_id
                return el
            }).filter(el => el.account_type.includes('margin'))
        },
        EXCHANGE_SPOT(state) {
            return state.spot
        },
        EXCHANGE_MARGIN(state) {
            return state.margin
        },
    }
}
export default Exchanges