import api from "@/api"

const TwitsModule = {
    state: () => ({
        twits: [],
        twitsPageCount: 0,
        selectedTwits: []
    }),
    mutations: {
        writeTwits(state, payload) {
            state.twits = payload.twits
            state.twitsPageCount = payload.pages
        },
        wrightSelectedTwits(state, payload) {
            state.selectedTwits = payload
        }
    },
    actions: {
        async getTWITS({commit}, payload) {
            const getPage = payload ? `?page=${payload}` : ''
            try {
                const data = await api.getTwits({args: getPage})
                const pages = Math.ceil(data.count / 50)
                commit('writeTwits', {twits: data.results.data, pages: pages})
                // commit('writeTitle', data.results.title)
                commit('writeFilters', {symbols: []})

            } catch (err) {
                console.log('error', err)
            }
        },
        async setTWITS({dispatch}, payload) {
            try {
                await api.connectTwits({data: payload}).finally(() => dispatch('getTWITS'))
            } catch (err) {
                console.log('error', err)
            }
        },
        async set_CORRESPONDING_TWITS({dispatch}, {from_twitter, to_channel}) {
            try {
                await api.setCorrespondingTwits({data: {from_twitter, to_channel}}).finally(() => dispatch('getTWITS'))
            } catch (err) {
                console.log('error', err)
            }
        },
        async delTWITS({dispatch}, payload) {
            try {
                await api.deleteTwits({args: `${payload}/`}).finally(() => dispatch('getTWITS'))
            } catch (err) {
                console.log('error', err)
            }
        },
        async massDelTWITS({dispatch}, payload) {
            try {
                await api.massDeleteTwits({data: payload}).finally(() => dispatch('getTWITS'))
            } catch (err) {
                console.log('error', err)
            }
        },
        async getTWITS_FROM({commit}, {id}) {
            try {
                const data = await api.getCorrespondingTwits({args: `${id}/`})
                if(data.Success) commit('wrightSelectedTwits', data.data)
            } catch (err) {
                console.log('error', err)
            }
        },
        async set_CORRESPONDENT_TWITS(context, {id, patch, to_channel}) {
            try {
                const data = await api.setCorrespondingTwits({args: `${id}/`, data: {patch, to_channel}})
                if (!data.Success) console.log(data.Errors)
            } catch (err) {
                console.log('error', err)
            }
        },
    },
    getters: {
        TWITS(state) {
            return state.twits
        },
        TWITS_PAGE_COUNT(state) {
            return state.twitsPageCount
        },
        GET_SELECTED_TWITS(state) {
            return state.selectedTwits
        }
    }
}

export default TwitsModule
